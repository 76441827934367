import type { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { useCallback, useState } from "react";

export const useText = (initialValue?: string) => {
  const [state, setState] = useState(initialValue);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const clear = useCallback(() => setState(""), []);

  return { state: [state, setState] as [string, Dispatch<SetStateAction<string>>], onChange, clear };
};
