import type { ConfigType } from "dayjs";
import type { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { useCallback, useState } from "react";
import { Day } from "src/libs/day";

export const useDate = (date?: ConfigType | Day) => {
  const [state, setState] = useState<Day>(new Day(date));

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setState(new Day(e.target.value));
  }, []);

  const clear = useCallback(() => setState(new Day()), []);

  return { state: [state, setState] as [Day, Dispatch<SetStateAction<Day>>], onChange, clear };
};
