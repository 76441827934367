import { chakra, Flex, HStack, ModalBody, ModalFooter, useDisclosure, VStack } from "@chakra-ui/react";
import type { ComponentProps, VFC } from "react";
import { useEffect, useState } from "react";
import { PrimaryButton } from "src/components/ui/buttons/PrimaryButton";
import { SecondaryButton } from "src/components/ui/buttons/SecondaryButton";
import { InputField } from "src/components/ui/form/InputField";
import { SelectField } from "src/components/ui/form/SelectField";
import { Icon } from "src/components/ui/Icon";
import { BaseModal } from "src/components/ui/modal/BaseModal";
import { DateRangeModal } from "src/components/ui/modal/DateRangeModal";
import { LinkText } from "src/components/ui/text/LinkText";
import { dateRangeText } from "src/constants";
import { options } from "src/constants/select";
import { Role } from "src/graphql/API";
import { useDateRange } from "src/hooks/value/useDateRange";
import { useText } from "src/hooks/value/useText";
import { Day } from "src/libs/day";
import { toCustomerOptions } from "src/libs/model/salesOffice";
import { useUserStore } from "src/store/useUserStore";
import type { DateRange, OrderCondition } from "src/types";
import { Status } from "src/types";

type Props = Pick<ComponentProps<typeof BaseModal>, "isOpen" | "onClose"> & {
  currentCondition: OrderCondition;
  onClickOk: (condition: OrderCondition) => void;
};

export const OrderConditionModal: VFC<Props> = (props) => {
  const { currentCondition, onClickOk, isOpen, onClose } = props;
  const { user } = useUserStore();
  const [selectedCustomerCode, setSelectedCustomerCode] = useState<string>();
  const [range, setRange] = useState<DateRange>("ALL");
  const { isOpen: isOpenDateRange, onClose: onCloseDateRange, onOpen: onOpenDateRange } = useDisclosure();
  const [status, setStatus] = useState<Status>(Status.ALL);
  const {
    start: [start, setStart],
    end: [end, setEnd],
    clearAll,
  } = useDateRange();
  const {
    state: [place, setPlace],
    onChange: onChangePlace,
    clear: clearPlace,
  } = useText("");
  const {
    state: [productNo, setProductNo],
    onChange: onChangeProductNo,
    clear: clearProductNo,
  } = useText("");
  const {
    state: [productName, setProductName],
    onChange: onChangeProductName,
    clear: clearProductName,
  } = useText("");

  useEffect(() => {
    if (!isOpen) return;
    setPlace(currentCondition.place);
    setProductNo(currentCondition.productNo);
    setProductName(currentCondition.productName);
    setStart(currentCondition.startDate);
    setEnd(currentCondition.endDate);
    setStatus(currentCondition.status);
    setRange(currentCondition.orderDateRange);
  }, [isOpen]);
  useEffect(() => {
    // NOTE: 小川従業員以外のユーザーの場合早期リターン
    if (!user?.salesOfficeCode) return;
    const customerCode = user.salesOffice?.customers?.items[0]?.code;

    if (!customerCode) return;
    setSelectedCustomerCode(customerCode);
    // NOTE: 非同期で取得した小川従業員ユーザー情報の得意先コードで初回検索
    onClickOk({
      place,
      productNo,
      productName,
      startDate: start,
      endDate: end,
      orderDateRange: range,
      status,
      customerCode,
    });
  }, [user]);

  const onClickConfirm = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const customerCode = user?.role === Role.CUSTOMER ? user.customerCode! : selectedCustomerCode;
    if (!customerCode) {
      console.assert("has no value customer code");
      onClose();
      return;
    }
    onClickOk({
      place,
      productNo,
      productName,
      startDate: start,
      endDate: end,
      orderDateRange: range,
      status,
      customerCode,
    });
    onClose();
  };

  const onClickReset = () => {
    setPlace("");
    setProductNo("");
    setProductName("");
    setStart(new Day());
    setEnd(new Day());
    setStatus(Status.ALL);
    setRange("ALL");
    setSelectedCustomerCode(user?.salesOffice?.customers?.items[0]?.code);
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalBody h="full">
        <Flex justifyContent="end">
          <LinkText textProps={{ fontSize: "sm" }} onClick={onClickReset}>
            絞り込み条件リセット
          </LinkText>
        </Flex>
        <chakra.form>
          <VStack spacing="2">
            <InputField label="備考(現場名)" value={place} onChange={onChangePlace} clear={clearPlace} />
            <InputField label="品番" value={productNo} onChange={onChangeProductNo} clear={clearProductNo} />
            <InputField label="品名" value={productName} onChange={onChangeProductName} clear={clearProductName} />
            <SelectField
              label="発送状況"
              value={status}
              onChange={(e) => setStatus(Number(e.target.value) as Status)}
              option={options["status"]}
            />
            {user?.role === Role.EMPLOYEE && (
              <SelectField
                label="得意先"
                value={selectedCustomerCode}
                onChange={(e) => setSelectedCustomerCode(e.target.value)}
                option={toCustomerOptions(user?.salesOffice ?? null, false)}
              />
            )}
            <InputField
              label="注文日"
              value={range === "MANUAL" ? Day.dateRangeDisplay(start, end) : dateRangeText[range]}
              readOnly
              _readOnly={{ opacity: 1, cursor: "pointer" }}
              rightIcon={<Icon name="calendar" />}
              textAlign="center"
              onClick={onOpenDateRange}
            />
          </VStack>
          <DateRangeModal
            isOpen={isOpenDateRange}
            onClose={onCloseDateRange}
            range={range}
            setRange={setRange}
            start={start}
            end={end}
            onClickConfirm={(s, e) => {
              setStart(s);
              setEnd(e);
            }}
            clearDateRange={clearAll}
          />
        </chakra.form>
      </ModalBody>
      <ModalFooter>
        <HStack w="full" spacing="4" justifyContent="space-around">
          <SecondaryButton w="full" onClick={onClose}>
            キャンセル
          </SecondaryButton>
          <PrimaryButton w="full" onClick={onClickConfirm}>
            適用
          </PrimaryButton>
        </HStack>
      </ModalFooter>
    </BaseModal>
  );
};
