import { chakra, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import type { VFC } from "react";
import type { OrderInfo } from "src/graphql/API";
import { Day } from "src/libs/day";

type Props = {
  order: OrderInfo;
  showOrderNo?: boolean;
};

const width = "120px";

export const OrderTable: VFC<Props> = (props) => {
  const { order, showOrderNo = false } = props;
  return (
    <Table mb="4" bg="white" borderRadius="md">
      <Tbody>
        {showOrderNo && (
          <Tr>
            <Td w={width} verticalAlign="top">
              注文番号
            </Td>
            <Td pl="0" pr="2" whiteSpace="pre-wrap">
              {order.orderNo}
            </Td>
          </Tr>
        )}
        <Tr>
          <Td w={width} verticalAlign="top">
            注文日
          </Td>
          <Td pl="0" pr="2" whiteSpace="pre-wrap">
            {new Day(order.orderedAt).format("YYYY/MM/DD")}
          </Td>
        </Tr>
        <Tr>
          <Td w={width} verticalAlign="top" pr="0">
            備考<chakra.span fontSize="xs">(現場名)</chakra.span>
          </Td>
          <Td pl="0" pr="2" whiteSpace="pre-wrap">
            {order.remark1 && <p>{order.remark1}</p>}
            {order.remark2 && <p>{order.remark2}</p>}
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
