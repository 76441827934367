import {
  Alert,
  Box,
  Center,
  chakra,
  Circle,
  Fade,
  Slide,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroller";
import { OrderCard } from "src/components/model/order/card/OrderCard";
import { OrderConditionModal } from "src/components/model/order/modal/OrderConditionModal";
import { OrderDetailModal } from "src/components/model/order/modal/OrderDetailModal";
import { BaseButton } from "src/components/ui/buttons/BaseButton";
import { BaseCard } from "src/components/ui/card/BaseCard";
import { HtmlHead } from "src/components/ui/HtmlHead";
import { Icon } from "src/components/ui/Icon";
import { Layout } from "src/components/ui/layout/Layout";
import { Loading } from "src/components/ui/Loading";
import { LinkText } from "src/components/ui/text/LinkText";
import { dateRangeText } from "src/constants";
import { options } from "src/constants/select";
import { Role } from "src/graphql/API";
import { useOrder } from "src/hooks/order/useOrder";
import { useStorage } from "src/hooks/useStorage";
import { useToast } from "src/hooks/useToast";
import { Day } from "src/libs/day";
import { useUserStore } from "src/store/useUserStore";
import type { OrderCondition, OrderInfo } from "src/types";
import { Status } from "src/types";

const Page: NextPage = () => {
  const [condition, setCondition] = useState<OrderCondition>({
    place: "",
    productNo: "",
    productName: "",
    startDate: new Day(),
    endDate: new Day(),
    orderDateRange: "ALL",
    status: Status.ALL,
    customerCode: "",
  });
  const [showFloatButton, setShowFloatButton] = useState(false);
  const { isOpen: isOpenCondition, onClose: onCloseCondition, onOpen: onOpenCondition } = useDisclosure();
  const [selectedOrder, setSelectedOrder] = useState<OrderInfo | null>(null);
  const router = useRouter();
  const {
    orderInfoList,
    getOrders,
    getNextOrders,
    hasMoreOrders,
    getOrderById,
    isLoading: { isOrdersLoading, isOrderLoading },
  } = useOrder();
  const { user } = useUserStore();
  const [getShowAttention, setShowAttention] = useStorage<boolean>("show-attention");
  const [showAttention] = useState(getShowAttention() ?? true);
  const { toast } = useToast();

  useEffect(() => {
    const fn = () => {
      const h = window.scrollY;
      if (h > 800) {
        setShowFloatButton(true);
      } else {
        setShowFloatButton(false);
      }
    };
    document.addEventListener("scroll", fn);
    return () => {
      document.removeEventListener("scroll", fn);
    };
  }, []);

  useEffect(() => {
    const { no } = router.query;
    if (!no) return;
    (async () => {
      const { data: orderInfo, errors } = await getOrderById(no as string);
      if (errors !== null) {
        toast.error();
        return;
      }
      if (orderInfo === null) {
        toast.warning({ description: "お探しの注文情報は見つかりませんでした" });
        return;
      }
      if (user?.role === Role.CUSTOMER) {
        if (user?.customer?.code !== orderInfo?.order.customerCode) {
          router.push("forbidden");
          return;
        }
      }
      setSelectedOrder(orderInfo);
    })();
  }, []);

  useEffect(() => {
    let customerCode = "";
    if (user?.salesOfficeCode) {
      customerCode = user.salesOffice?.customers?.items[0]?.code ?? "";
    } else if (user?.customerCode) {
      customerCode = user.customerCode ?? "";
    }
    if (customerCode) {
      setCondition((prev) => ({ ...prev, customerCode }));
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const { errors } = await getOrders(condition);
      if (errors !== null) {
        toast.error();
        return;
      }
    })();
  }, [condition]);

  const onClickOrder = (orderNo: string) => {
    window.history.replaceState(null, "", `${router.pathname}?no=${orderNo}`);
  };
  const onCloseOrderedProductModal = () => {
    setSelectedOrder(null);
    window.history.replaceState(null, "", `${router.pathname}`);
  };

  const onClickAttention = () => {
    setShowAttention(false);
    router.push("/attention");
  };

  const onClickOk = (condition: OrderCondition) => setCondition(condition);

  const loadMore = async () => {
    const { errors } = await getNextOrders(condition);
    if (errors !== null) {
      toast.error();
      return;
    }
  };
  return (
    <Layout px="0">
      <HtmlHead title="注文一覧" />
      {isMobile ? (
        <BaseButton
          position="fixed"
          bottom="12"
          p="6"
          boxShadow="3px 3px 3px rgba(0,0,0,0.6)"
          right="4"
          ml="2"
          bg="gray.300"
          onClick={onOpenCondition}
        >
          絞り込み
        </BaseButton>
      ) : (
        <Box position="fixed" top="60px" left="220px" mt={showAttention ? "60px" : undefined}>
          <LinkText textProps={{ mx: "4", textAlign: isMobile ? "right" : "left" }} onClick={onOpenCondition}>
            絞り込み
          </LinkText>
        </Box>
      )}
      <Fade in={showFloatButton}>
        <Circle
          position="fixed"
          bottom="36"
          p="6"
          boxShadow="3px 3px 3px rgba(0,0,0,0.6)"
          right="4"
          ml="2"
          size="40px"
          bg="black"
          opacity="0.6"
          color="white"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <Icon name="double-up" />
        </Circle>
      </Fade>
      <Slide direction="top" in={showAttention}>
        <Alert status="warning" mt="60px" ml={!isMobile ? "220px" : undefined}>
          最初にお読みください：
          <LinkText onClick={onClickAttention}>使用上の注意</LinkText>
        </Alert>
      </Slide>

      <Box pb="8" h="full">
        {isOrdersLoading || isOrderLoading ? (
          <Center h="full" alignItems="center">
            <Loading size="xl" />
          </Center>
        ) : !orderInfoList || orderInfoList.length === 0 ? (
          <Center h="full" alignItems="center">
            <BaseCard bg="white" py="8" px="4">
              <VStack spacing="4">
                <Text fontSize="md" fontWeight="bold" textAlign="center">
                  何も見つかりませんでした
                  <br />
                  「絞り込み」から
                  <br />
                  条件を変更してください
                </Text>
                <Box w="full">
                  <Center>
                    <Alert bg="gray.100" w="fit-content" py="1" rounded="full">
                      <Text fontSize="sm">現在の絞り込み条件</Text>
                    </Alert>
                  </Center>
                  <Table>
                    <Tbody>
                      <Tr>
                        <Td px="2">
                          備考
                          <br />
                          <chakra.span fontSize="xs">(現場名)</chakra.span>
                        </Td>
                        <Td px="2">{condition.place}</Td>
                      </Tr>
                      <Tr>
                        <Td px="2">品番</Td>
                        <Td px="2">{condition.productNo}</Td>
                      </Tr>
                      <Tr>
                        <Td px="2">品名</Td>
                        <Td px="2">{condition.productName}</Td>
                      </Tr>
                      <Tr>
                        <Td px="2">発送状況</Td>
                        <Td px="2">{options.status.find((item) => item.value === condition.status)?.text}</Td>
                      </Tr>
                      {user?.role === Role.EMPLOYEE && (
                        <Tr>
                          <Td px="2">得意先</Td>
                          <Td px="2">
                            {
                              user?.salesOffice?.customers?.items.find((item) => item?.code === condition.customerCode)
                                ?.name
                            }
                          </Td>
                        </Tr>
                      )}
                      <Tr>
                        <Td px="2">注文日</Td>
                        <Td px="2">
                          {condition.orderDateRange === "MANUAL"
                            ? Day.dateRangeDisplay(condition.startDate, condition.endDate)
                            : dateRangeText[condition.orderDateRange]}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </VStack>
            </BaseCard>
          </Center>
        ) : (
          <Box mt={showAttention ? "60px" : undefined}>
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMoreOrders}
              loader={
                <Center h="full" alignItems="center">
                  <Loading size="lg" />
                </Center>
              }
            >
              {orderInfoList.map((orderInfo) => (
                <Center px="2" py="4" key={orderInfo.order.orderNo}>
                  <OrderCard
                    orderInfo={orderInfo}
                    onClick={() => {
                      setSelectedOrder(orderInfo);
                      onClickOrder(orderInfo.order.orderNo);
                    }}
                  />
                </Center>
              ))}
            </InfiniteScroll>
          </Box>
        )}
      </Box>
      {selectedOrder && <OrderDetailModal isOpen onClose={onCloseOrderedProductModal} orderInfo={selectedOrder} />}
      <OrderConditionModal
        currentCondition={condition}
        onClickOk={onClickOk}
        isOpen={isOpenCondition}
        onClose={onCloseCondition}
      />
    </Layout>
  );
};

export default Page;
