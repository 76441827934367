import { Box, chakra, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import type { VFC } from "react";
import { OrderTable } from "src/components/model/order/table/OrderTable";
import { BaseCard } from "src/components/ui/card/BaseCard";
import { StatusLabel } from "src/components/ui/StatusLabel";
import type { OrderInfo } from "src/types";

type Props = {
  orderInfo: OrderInfo;
  onClick: () => void;
};

export const OrderCard: VFC<Props> = (props) => {
  const { orderInfo, onClick } = props;
  return (
    <BaseCard pb="2" onClick={onClick} cursor="pointer">
      <OrderTable order={orderInfo.order} />
      <Box borderWidth="1px" borderColor="gray.200" m="2" borderRadius="md">
        <Table w="full">
          <Thead>
            <Tr>
              <Th>品名</Th>
              <Th pl="2">数量</Th>
              <Th>状態</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderInfo.products.map((product) => (
              <Tr key={product.productId}>
                <Td
                  borderBottom="0px"
                  maxW="0"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  pr="0"
                  pl="2"
                >
                  <chakra.span fontSize="xs">{product.productName}</chakra.span>
                </Td>
                <Td borderBottom="0px" pl="2" isNumeric w="60px" whiteSpace="nowrap">
                  <chakra.span fontSize="xs">{product.quantity}</chakra.span>
                </Td>
                <Td borderBottom="0px" w="80px" px="0" whiteSpace="nowrap">
                  <StatusLabel status={product.productStatus} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </BaseCard>
  );
};
