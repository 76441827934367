import type { ChangeEventHandler } from "react";
import { useCallback } from "react";
import { useToast } from "src/hooks/useToast";
import type { TemplateType } from "src/libs/day";
import { Day } from "src/libs/day";

import { useDate } from "./useDate";

type Props = { start: Day; end: Day };

export const useDateRange = (props?: Props) => {
  const {
    state: [start, setStart],
    onChange: onChangeStart,
    clear: clearStart,
  } = useDate(props?.start);
  const {
    state: [end, setEnd],
    onChange: onChangeEnd,
    clear: clearEnd,
  } = useDate(props?.end);

  const clearAll = useCallback(() => {
    clearStart();
    clearEnd();
  }, [clearEnd, clearStart]);
  const { toast } = useToast();

  const _onChangeStart: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!e.target.value) {
        toast.warning({ description: "開始日は必須項目です" });
        return;
      }
      const day = new Day(e.target.value);
      if (!day.isValidStartDate(end)) {
        toast.warning({ description: "終了日より後に設定できません" });
        return;
      }
      onChangeStart(e);
    },
    [end, onChangeStart, toast]
  );

  const _onChangeEnd: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!e.target.value) {
        toast.warning({ description: "終了日は必須項目です" });
        return;
      }
      const day = new Day(e.target.value);
      if (!day.isValidEndDate(start)) {
        toast.warning({ description: "開始日より前に設定できません" });
        return;
      }
      onChangeEnd(e);
    },
    [onChangeEnd, start, toast]
  );

  return {
    start: [start, setStart] as ReturnType<typeof useDate>["state"],
    end: [end, setEnd] as ReturnType<typeof useDate>["state"],
    onChangeStart: _onChangeStart,
    onChangeEnd: _onChangeEnd,
    clearStart,
    clearEnd,
    clearAll,
  };
};
