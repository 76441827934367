import { Box, HStack, ModalBody, Text, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import type { ComponentProps, VFC } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { OrderTable } from "src/components/model/order/table/OrderTable";
import { ProductCard } from "src/components/model/product/card/ProductCard";
import { BaseButton } from "src/components/ui/buttons/BaseButton";
import { BaseCard } from "src/components/ui/card/BaseCard";
import { BaseModal } from "src/components/ui/modal/BaseModal";
import { Role } from "src/graphql/API";
import { useMixpanelOrder } from "src/hooks/order/useMixpanelOrder";
import { useToast } from "src/hooks/useToast";
import { useUserStore } from "src/store/useUserStore";
import type { OrderInfo } from "src/types";

type Props = Pick<ComponentProps<typeof BaseModal>, "isOpen" | "onClose"> & {
  orderInfo: OrderInfo;
};

export const OrderDetailModal: VFC<Props> = (props) => {
  const { orderInfo, isOpen, onClose } = props;
  const { order, products } = orderInfo;
  const { toast } = useToast();
  const { user } = useUserStore();
  const router = useRouter();
  const { trackOrderDetailView, trackOrderDetailShare, trackOrderDetailProductListView } = useMixpanelOrder();

  // NOTE: ブラウザバック時にモーダルを閉じる
  useEffect(() => {
    if (!isOpen) return;
    history.pushState(null, "", null);
    const fn = () => {
      history.pushState(null, "", null);
      onClose();
    };
    window.addEventListener("popstate", fn);
    return () => {
      window.removeEventListener("popstate", fn);
    };
  }, [isOpen]);

  useEffect(() => {
    if (user?.role === Role.CUSTOMER) {
      if (user?.customer?.code !== orderInfo.order.customerCode) {
        router.push("forbidden");
      }
    }
  }, [user, orderInfo]);

  useEffect(() => {
    if (!isOpen) return;
    trackOrderDetailView(orderInfo.order.orderNo);
    trackOrderDetailProductListView(orderInfo.order, products);
  }, [isOpen, orderInfo.order.orderNo, trackOrderDetailView, trackOrderDetailProductListView]);

  const onClickShare = async () => {
    try {
      trackOrderDetailShare(orderInfo.order.orderNo);

      if (!navigator.share) {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(window.location.href);
          toast.info({ description: "URLをコピーしました" });
          return;
        }
        toast.warning({ description: "ご利用のブラウザでは共有できません" });
        return;
      }

      await navigator.share({
        title: "Ogawa Finder",
        text: window.location.href,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} modalSize={isMobile ? "full" : undefined} bg="base">
      <ModalBody>
        <Box bg="base" maxH="full" mt="4">
          <VStack justifyContent="center" bg="base" h="full" overflow="scroll" pb="2" spacing="4" px="2">
            <Text fontSize="xl">注文情報</Text>
            <BaseCard w="full">
              <OrderTable showOrderNo order={order} />
            </BaseCard>
            <Text fontSize="xl">明細情報</Text>
            {products.map((item) => (
              <ProductCard key={item?.productId} product={item} />
            ))}
          </VStack>
          <HStack spacing="2" mt="4" justifyContent="center" position="sticky" bottom="4" pb="2">
            <BaseButton
              onClick={onClose}
              borderWidth="1px"
              borderColor="border.main"
              boxShadow="5px 5px 5px rgba(0,0,0,0.4)"
            >
              一覧へ戻る
            </BaseButton>
            <BaseButton
              onClick={onClickShare}
              borderWidth="1px"
              borderColor="border.main"
              boxShadow="5px 5px 5px rgba(0,0,0,0.4)"
            >
              共有する
            </BaseButton>
          </HStack>
        </Box>
      </ModalBody>
    </BaseModal>
  );
};
