import { useCallback } from "react";

type Key = "show-attention";

export const useStorage = <T>(key: Key) => {
  const setItem = useCallback((item: T) => {
    const obj = { data: item };
    const json = JSON.stringify(obj);
    localStorage.setItem(key, json);
  }, []);

  const getItem = useCallback((): T | null => {
    const item = localStorage.getItem(key);
    if (!item) return null;
    const obj = JSON.parse(item);
    return obj.data as T;
  }, []);

  return [getItem, setItem] as [() => T | null, (item: T) => void];
};
