import { HStack, ModalBody, ModalFooter, VStack } from "@chakra-ui/react";
import type { ComponentProps, VFC } from "react";
import { useEffect } from "react";
import { PrimaryButton } from "src/components/ui/buttons/PrimaryButton";
import { SecondaryButton } from "src/components/ui/buttons/SecondaryButton";
import { InputField } from "src/components/ui/form/InputField";
import { BaseModal } from "src/components/ui/modal/BaseModal";
import { useDateRange } from "src/hooks/value/useDateRange";
import type { Day } from "src/libs/day";

type ReturnUseDateRange = ReturnType<typeof useDateRange>;

type Props = Pick<ComponentProps<typeof BaseModal>, "isOpen" | "onClose"> & {
  onClickConfirm: (start: Day, end: Day) => void;
  start: ReturnUseDateRange["start"][0];
  end: ReturnUseDateRange["end"][0];
};

export const DateRangePickerModal: VFC<Props> = (props) => {
  const { isOpen, onClose, onClickConfirm, start, end } = props;
  const {
    start: [_start, setStart],
    end: [_end, setEnd],
    onChangeStart,
    onChangeEnd,
  } = useDateRange({ start, end });

  useEffect(() => {
    if (isOpen) {
      setStart(start);
      setEnd(end);
    }
  }, [isOpen]);

  const _onClickConfirm = () => {
    onClickConfirm(_start, _end);
    onClose();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} modalSize="xs">
      <ModalBody>
        <VStack>
          <InputField label="開始日" type="date" value={_start.toFieldValue()} onChange={onChangeStart} />
          <InputField label="終了日" type="date" value={_end.toFieldValue()} onChange={onChangeEnd} />
        </VStack>
      </ModalBody>
      <ModalFooter>
        <HStack w="full" spacing="4" justifyContent="space-around">
          <SecondaryButton w="full" onClick={onClose}>
            キャンセル
          </SecondaryButton>
          <PrimaryButton w="full" onClick={_onClickConfirm}>
            OK
          </PrimaryButton>
        </HStack>
      </ModalFooter>
    </BaseModal>
  );
};
