import type { ColorProps } from "@chakra-ui/react";
import { Box, HStack } from "@chakra-ui/react";
import type { VFC } from "react";
import { Icon } from "src/components/ui/Icon";
import { statusValue } from "src/constants";
import type { ProductStatus } from "src/graphql/API";

type Props = {
  status: ProductStatus;
};

export const StatusLabel: VFC<Props> = (props) => {
  const { status } = props;
  const isCompleted = status === "COMPLETED";
  const isCanceled = status === "CANCELED";
  const color: ColorProps["color"] = isCompleted ? "success" : isCanceled ? "error" : "secondary.main";
  return (
    <HStack
      w="70px"
      borderRadius="26px"
      padding="2px 4px"
      borderWidth="1px"
      borderColor={color}
      justifyContent="center"
    >
      {isCompleted && <Icon name="checked" fontSize="xs" color={color} />}
      <Box whiteSpace="nowrap" fontSize="xs" style={{ margin: "0px 4px" }} color={color}>
        {statusValue[status]}
      </Box>
    </HStack>
  );
};
