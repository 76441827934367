import { Box, HStack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import type { VFC } from "react";
import { Fragment } from "react";
import { BaseCard } from "src/components/ui/card/BaseCard";
import { Icon } from "src/components/ui/Icon";
import { ProductStatus } from "src/graphql/API";
import { Day } from "src/libs/day";
import type { OrderInfo } from "src/types";

type Props = {
  product: OrderInfo["products"][number];
};

const width = "120px";

export const ProductCard: VFC<Props> = (props) => {
  const { product } = props;

  const data = [
    { key: "品番", value: product.productCode },
    { key: "品名", value: product.productName },
    { key: "数量", value: product.quantity },
    { key: "定価", value: `￥${product.listPrice.toLocaleString()}` },
    { key: "単価", value: `￥${product.unitPrice.toLocaleString()}` },
    { key: "金額", value: `￥${product.amount.toLocaleString()}` },
  ];
  const dates = () => {
    const list = [
      { key: "注文日", value: new Day(product.orderedAt).format("YY/MM.DD") },
      {
        key: "手配日",
        value: product.arrangedAt ? new Day(product.arrangedAt).format("YY/MM.DD") : "ー",
      },
    ];
    if (product.slipDate) {
      list.push({
        key: "伝票日付",
        value: product.slipDate ? new Day(product.slipDate).format("YY/MM.DD") : "ー",
      });
    } else {
      list.push({
        key: "納品予定日",
        value: product.deliveryDate ? new Day(product.deliveryDate).format("YY/MM.DD") : "ー",
      });
    }
    return list;
  };
  return (
    <BaseCard w="full">
      <Table mb="4" bg="white" rounded="md">
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td w={width} verticalAlign="top">
                {item.key}
              </Td>
              <Td pl="0" whiteSpace="pre-wrap">
                {item.value}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <HStack justifyContent="space-around" px="2" pb="2">
        {dates().map((item, index) => (
          <Fragment key={index}>
            {index !== 0 && <Icon name="right2x" boxSize="3" />}
            <Box>
              <Text fontSize="xs" color="secondary.main">
                {item.key}
              </Text>
              <Text textAlign="center">{item.value}</Text>
            </Box>
          </Fragment>
        ))}
        <Box w="30px">
          {product.productStatus === ProductStatus.CANCELED ? (
            <Icon name="canceled" color="error" boxSize="8" />
          ) : (
            product.slipDate && <Icon name="circle-checked" color="success" boxSize="8" />
          )}
        </Box>
      </HStack>
    </BaseCard>
  );
};
