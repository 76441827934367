import { HStack, ModalBody, ModalFooter, useDisclosure, VStack } from "@chakra-ui/react";
import type { ComponentProps, Dispatch, SetStateAction, VFC } from "react";
import { BaseButton } from "src/components/ui/buttons/BaseButton";
import { SecondaryButton } from "src/components/ui/buttons/SecondaryButton";
import { BaseModal } from "src/components/ui/modal/BaseModal";
import { DateRangePickerModal } from "src/components/ui/modal/DateRangePickerModal";
import { dateRanges, dateRangeText } from "src/constants";
import type { DateRange } from "src/types";

type DateRangePickerModalProps = ComponentProps<typeof DateRangePickerModal>;

type Props = Pick<ComponentProps<typeof BaseModal>, "isOpen" | "onClose"> &
  Pick<DateRangePickerModalProps, "start" | "end" | "onClickConfirm"> & {
    range: DateRange;
    setRange: Dispatch<SetStateAction<DateRange>>;
    clearDateRange: () => void;
  };

export const DateRangeModal: VFC<Props> = (props) => {
  const { isOpen, onClose, range, setRange, clearDateRange, onClickConfirm, ...dateRangeProps } = props;
  const { isOpen: isOpenPicker, onClose: onClosePicker, onOpen: onOpenPicker } = useDisclosure();

  const onClickRangeType = (value: DateRange) => {
    if (value === "MANUAL") {
      onOpenPicker();
      return;
    }
    setRange(value);
    clearDateRange();
    onClose();
  };

  const _onClickConfirm: typeof onClickConfirm = (s, e) => {
    onClickConfirm(s, e);
    setRange("MANUAL");
    onClosePicker();
    onClose();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} modalSize="xs">
      <ModalBody>
        <VStack>
          {dateRanges.map((item, index) => (
            <BaseButton
              key={index}
              w="full"
              bg={item === range ? "primary.main" : "white"}
              color={item === range ? "white" : undefined}
              variant="outline"
              onClick={() => onClickRangeType(item)}
            >
              {dateRangeText[item]}
            </BaseButton>
          ))}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <HStack w="full" spacing="4" justifyContent="space-around">
          <SecondaryButton w="full" onClick={onClose}>
            閉じる
          </SecondaryButton>
        </HStack>
      </ModalFooter>
      <DateRangePickerModal
        isOpen={isOpenPicker}
        onClose={onClosePicker}
        onClickConfirm={_onClickConfirm}
        {...dateRangeProps}
      />
    </BaseModal>
  );
};
