import type { SelectOption } from "src/types";
import { Status } from "src/types";

export const options: Record<"status", SelectOption[]> = {
  status: [
    { value: Status.ALL, text: "すべて" },
    { value: Status.IN_PROGRESS, text: "未発送" },
    { value: Status.COMPLETED, text: "発送済" },
  ],
};
